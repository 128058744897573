import { Models } from '@mtap-smartcity/lib-api';
import { TranslateResult } from 'vue-i18n';

// interface Dali1TelemetryProperties {
//   commandReceivedSinceLastPowerOn: boolean;
//   enabled: boolean;
//   hasShortAddress: boolean;
//   isFadeRunning: boolean;
//   lastLightLevelCommandWasValid: boolean;
//   resetState: boolean;
//   status: number;
//   statusMessage: string;
//   deltaActiveEnergy: number;
//   type: 'Dali 1.0'
// }
//
// interface Dali2TelemetryProperties {
//   set_duty: number;
//   activePower: number;
//   status: number;
//   statusMessage: string;
//   activeEnergy: number;
//   apparentPower: number;
//   apparentEnergy: number;
//   loadsidePower: number;
//   loadsideEnergy: number;
//   controlGearOperatingTime: number;
//   controlGearPowerFactor: number;
//   controlGearExternalSupplyVoltage: number;
//   controlGearExternalSupplyVoltageFrequency: number;
//   controlGearTemperature: number;
//   controlGearOutputCurrentPercent: number;
//   lightSourceVoltage: number;
//   lightSourceCurrent: number;
//   lightSourceTemperature: number;
//   lightSourceOnTime: number;
//   measuredDuty: number;
//   timestamp: number;
//   deltaActiveEnergy: number;
//   type: 'Dali 2.0'
// }
//
// interface SensorTelemetryProperties {
//   CO: number,
//   IAQ: number,
//   NO2: number,
//   PM10: number,
//   PM2_5: number,
//   PM_1: number,
//   accelX: number,
//   accelY: number,
//   accelZ: number,
//   gasResistance: number,
//   humidity: number,
//   pressure: number,
//   temperature: number,
// }
//
// enum AirQualityIndex {
//   CO = 'CO',
//   IAQ = 'IAQ',
//   NO2 = 'NO2',
//   PM10 = 'PM10',
//   PM2_5 = 'PM2_5',
//   PM_1 = 'PM_1',
//   accelX = 'accelX',
//   accelY = 'accelY',
//   accelZ = 'accelZ',
//   humidity = 'humidity',
//   pressure = 'pressure',
//   temperature = 'temperature',
// }
//
// interface ControlCabinetTelemetryProperties {
//   activeEnergy: number
// }
//
// interface ITelemetry {
//   id: number;
//   device_id: number;
//   object_id: string;
//   device_type: 'lamp' | 'sensor' | 'control-cabinet';
//   controller_type: Models.Devices.ControllerType;
//   telemetry_version: number;
//   telemetry: Dali2TelemetryProperties | Dali1TelemetryProperties | SensorTelemetryProperties | ControlCabinetTelemetryProperties;
//   timestamp: string;
// }
//
// interface Dali2Telemetry extends ITelemetry {
//   device_type: 'lamp',
//   telemetry: Dali2TelemetryProperties
// }
//
// interface Dali1Telemetry extends ITelemetry {
//   device_type: 'lamp',
//   telemetry: Dali1TelemetryProperties
// }
//
// interface SensorTelemetry extends ITelemetry {
//   device_type: 'sensor',
//   controller_type: 'iothub'
//   telemetry: SensorTelemetryProperties
// }
//
// interface ControlCabinetTelemetry extends ITelemetry {
//   device_type: 'control-cabinet',
//   controller_type: 'iothub',
//   telemetry: ControlCabinetTelemetryProperties
// }
//
// type Telemetry = Dali1Telemetry | Dali2Telemetry | SensorTelemetry | ControlCabinetTelemetry

interface TelemetryIndexMeta {
  min?: number,
  max?: number,
  unit: string
}

interface TelemetryIndex extends TelemetryIndexMeta {
  value: number | null;
  name: TranslateResult;
}

enum gatewayControlOptions {
  'cascade_control' = 'cascadeControl',
  'manual_control' = 'manualControl',
  'dusk_sensor_control' = 'duskSensorControl',
  'system_mTap_control' = 'systemMtapControl',
  'off' = 'off',
}

type GatewayControlCabinetControl = (
  'cascade_control'
  | 'manual_control'
  | 'dusk_sensor_control'
  | 'system_mTap_control'
  | 'off'
);

type MinMaxTelemetryIndex = Required<TelemetryIndex>

export interface GatewayControlCabinet1V1PropertiesWithCO2 extends Models.Telemetries.GatewayControlCabinet1V1Properties {
  co2Emission: number
}

export interface Dali2PropertiesPropertiesWithTotalEnergy extends Models.Telemetries.Dali2Properties {
  totalEnergy: number
}

export interface LampMtap3V1PropertiesPropertiesWithKobize extends Models.Telemetries.LampMtap3V1Properties {
  totalEnergy: number
  co2Emission: number
}

export interface CircuitGroupProporties {
  activePower: number
}

type TelemetryIndicesMap<T extends
  Models.Telemetries.LampMtap3V1Properties
  | Models.Telemetries.Dali2Properties
  | Models.Telemetries.SensorMtap1V1Properties
  | Models.Telemetries.LampControlCabinet1V1Properties
  | Models.Telemetries.GatewayControlCabinet1V1Properties
  | GatewayControlCabinet1V1PropertiesWithCO2
  | Dali2PropertiesPropertiesWithTotalEnergy
  | LampMtap3V1PropertiesPropertiesWithKobize
  | CircuitGroupProporties
> = {
  [K in keyof T]?: TelemetryIndexMeta
}

export {
//   Dali1TelemetryProperties,
//   Dali2TelemetryProperties,
//   SensorTelemetryProperties,
//   ControlCabinetTelemetryProperties,
//   Dali2Telemetry,
//   Dali1Telemetry,
//   SensorTelemetry,
//   ControlCabinetTelemetry,
//   Telemetry,
  gatewayControlOptions,
  TelemetryIndexMeta,
  TelemetryIndicesMap,
  TelemetryIndex,
  MinMaxTelemetryIndex,
  GatewayControlCabinetControl
//   AirQualityIndex
};
