






























import {
  Component, Vue, Prop, Watch
} from 'vue-property-decorator';
import {
  TelemetryIndex, MinMaxTelemetryIndex, TelemetryIndexMeta, gatewayControlOptions
} from '@/types/telemetry';
import { Models } from '@mtap-smartcity/lib-api';
import { dateTimeToStr } from '@/utils/time_format';
import { TranslateResult } from 'vue-i18n';
import { hasMaxPower, isLamp } from '@/utils/type_check';
import lampTelemetry from '@/constants/lamp_telemetry';
import controlCabinetTelemetry from '@/constants/control_cabinet_telemetry';
import SemiCircleGaugesCard from '../dataDisplayComponents/SemiCircleGaugesCard.vue';
import TimeMetersCard from './TimeMetersCard/TimeMetersCard.vue';
import DeviceStatusIndices from './DeviceStatusIndices/DeviceStatusIndices.vue';
import { namespace } from 'vuex-class';
import { TelemetryAction, TelemetryActionType, TelemetryState } from '@/store/modules/telemetry/types';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';
import { ControllerTypes } from '../AnalyticsCard.vue';
import { isInAllowedDelay } from '@/utils/allowed_delay';
import { AppGetter, AppGetterType } from '@/store/modules/app/types';

const app = namespace('app');
const telemetry = namespace('telemetry');

const IothubControllerTelemetryCard: any = () => ({
  component: import('./DeviceTelemetryCards/IothubControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const IqrfControllerTelemetryCard: any = () => ({
  component: import('./DeviceTelemetryCards/IqrfControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const VeControllerTelemetryCard: any = () => ({
  component: import('./DeviceTelemetryCards/VeControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampMtap1V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampMtap1V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampMtap2V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampMtap2V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampMtap3V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampMtap3V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampMtap4V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampMtap4V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampMtap5V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampMtap5V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampVe1V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampVe1V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampVe2V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampVe2V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampVe3V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampVe3V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const LampControlCabinet1V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/LampControlCabinetControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const GatewayControlCabinet1V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/GatewayControlCabinet1V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const SensorMtap1V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/SensorMtap1V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const GatewayMtap1V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/GatewayMtap1V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const GatewayVe1V1Telemetry: any = () => ({
  component: import('./DeviceTelemetryCards/GatewayVe1V1ControllerTelemetryCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

@Component({
  components: {
    DeviceStatusIndices,
    SemiCircleGaugesCard,
    TimeMetersCard,
    [ControllerTypes.ve]: VeControllerTelemetryCard,
    [ControllerTypes.iqrf]: IqrfControllerTelemetryCard,
    [ControllerTypes.iothub]: IothubControllerTelemetryCard,
    [ControllerTypes.LAMP_MTAP_1_V1]: LampMtap1V1Telemetry,
    [ControllerTypes.LAMP_MTAP_2_V1]: LampMtap2V1Telemetry,
    [ControllerTypes.LAMP_MTAP_3_V1]: LampMtap3V1Telemetry,
    [ControllerTypes.LAMP_MTAP_4_V1]: LampMtap4V1Telemetry,
    [ControllerTypes.LAMP_MTAP_5_V1]: LampMtap5V1Telemetry,
    [ControllerTypes.LAMP_MTAP_6_V1]: LampMtap3V1Telemetry,
    [ControllerTypes.LAMP_VE_1_V1]: LampVe1V1Telemetry,
    [ControllerTypes.LAMP_VE_2_V1]: LampVe2V1Telemetry,
    [ControllerTypes.LAMP_VE_3_V1]: LampVe3V1Telemetry,
    [ControllerTypes.LAMP_CONTROL_CABINET_1_V1]: LampControlCabinet1V1Telemetry,
    [ControllerTypes.GATEWAY_CONTROL_CABINET_1_V1]: GatewayControlCabinet1V1Telemetry,
    [ControllerTypes.SENSOR_MTAP_1_V1]: SensorMtap1V1Telemetry,
    [ControllerTypes.GATEWAY_MTAP_1_V1]: GatewayMtap1V1Telemetry,
    [ControllerTypes.GATEWAY_VE_1_V1]: GatewayVe1V1Telemetry
  }
})
/**
 * @group Analytics Card
 * Telemetry tab with lamp stats
 */
export default class AnalyticsCardTelemetryTab extends Vue {
  // Selected dali2 (!) lamp data object (stats displayed in the component are available only for dali2 lamps)
  @Prop({
    type: Object,
  }) readonly selectedDevice!: Models.Devices.Device | null;

  @Prop({
    type: Object,
    // temporary 'any | null' type to be transformed into 'Models.Telemetries.Telemetry | null'
  }) readonly telemetryData!: any | null;

  @Prop({
    type: String,
  }) readonly telemetryControllerType!: Models.Constants.ControllerType | null;

  @telemetry.State
  co2Emission!: TelemetryState['co2Emission']

  @telemetry.State
  energyConsumption!: TelemetryState['energyConsumption']

  @app.Getter(AppGetter.GetRuntimeConfig)
  runtimeConfig!: AppGetterType['GET_RUNTIME_CONFIG'];

  @telemetry.Action(TelemetryAction.FetchDeviceKobize)
  fetchDeviceKobize!: TelemetryActionType['FETCH_DEVICE_KOBIZE'];

  intervalID!: any;

  get deviceIsLamp() {
    return this.selectedDevice ? isLamp(this.selectedDevice) : false;
  }

  get message(): TranslateResult | string {
    if (!this.selectedDevice) return '';
    if (!this.telemetryData) return this.$t('messages.error');
    return dateTimeToStr(this.telemetryData?.timestamp) ? dateTimeToStr(this.telemetryData?.timestamp) : this.$t('messages.error');
  }

  get deviceMaxPower() {
    if (!this.selectedDevice) return null;
    if (!isLamp(this.selectedDevice)) return null;
    if (!hasMaxPower(this.selectedDevice.parameters.device)) return null;
    return this.selectedDevice.parameters.device.maxPower;
  }

  get deviceStatusData() {
    if (!this.selectedDevice) return null;
    const powerStatus = this.lampIsOn !== null
      ? {
        value: this.lampIsOn,
        tooltip: this.lampIsOn ? this.$t('tooltips.deviceStatus[0]') : this.$t('tooltips.deviceStatus[1]')
      }
      : this.lampIsOn;
    return {
      errorStatus: {
        value: this.lampError,
        tooltip: this.telemetryData && this.telemetryData?.telemetry.status !== undefined
          ? this.$t(`deviceErrors[${this.telemetryData?.telemetry.status}]`)
          : this.$t(`deviceErrors[${0}]`),
      },
      powerStatus,
      onlineStatus: {
        value: this.deviceIsOnline,
        tooltip: this.deviceIsOnline ? this.$t('tooltips.connectionStatus[0]') : this.$t('tooltips.connectionStatus[1]')
      },
    };
  }

  get deviceIsOnline() {
    return isInAllowedDelay(this.telemetryData, this.runtimeConfig);
  }

  get lampIsOn(): boolean | null {
    let result: boolean | null = false;
    if (!isLamp(this.selectedDevice!)) return null;
    if (!this.deviceIsOnline) {
      result = false;
    } else if (this.telemetryData && (this.telemetryData?.telemetry.set_duty > 0 || this.telemetryData?.telemetry.setDuty > 0)) {
      result = true;
    }
    return result;
  }

  get lampError() {
    return Number(this.telemetryData?.telemetry.status) > 1;
  }

  get timeTelemetries() {
    const timeTelemetriesBuffer: TelemetryIndex[] = [];
    lampTelemetry.timeMeterIndicesNames.forEach((i) => {
      const indexMeta = lampTelemetry.lampTelemetryIndices[i];
      const value = this.telemetryData ? this.telemetryData?.telemetry[i] : null;
      const name = this.$t(`telemetries.${i}`);
      if (indexMeta.unit === 's') {
        timeTelemetriesBuffer.push({
          ...indexMeta,
          value,
          name
        });
      }
    });
    return timeTelemetriesBuffer;
  }

  get kobizeTelemetries() {
    const classicMeterDataBuffer: TelemetryIndex[] = [];
    lampTelemetry.timeMeterIndicesNames.forEach((i) => {
      const indexMeta = lampTelemetry.lampTelemetryIndices[i];
      const value = (Number(this[i]?.toFixed(2))) ?? 0;
      const name = this.$t(`telemetries.${i}`);
      if (indexMeta.unit !== 's') {
        classicMeterDataBuffer.push({
          ...indexMeta,
          value,
          name
        });
      }
    });
    return classicMeterDataBuffer;
  }

  get minMaxTelemetries() {
    const result: MinMaxTelemetryIndex[] = [];
    let indices: Required<TelemetryIndexMeta> | string[];

    switch (this.telemetryControllerType) {
      case 'LAMP_MTAP_3_V1':
      case 'LAMP_MTAP_6_V1':
        indices = lampTelemetry.lampMtap3V1IndicesNames;
        break;
      case 'LAMP_CONTROL_CABINET_1_V1':
        indices = lampTelemetry.lampControlCabinet1V1IndicesNames;
        break;
      case 'GATEWAY_CONTROL_CABINET_1_V1':
        indices = controlCabinetTelemetry.GatewayControlCabinet1V1IndicesNames;
        break;
      default:
        indices = lampTelemetry.lampIndicesNames;
        break;
    }

    indices.forEach((key) => {
      let indexMeta: Required<TelemetryIndexMeta>;

      switch (this.telemetryControllerType) {
        case 'LAMP_MTAP_3_V1':
        case 'LAMP_MTAP_6_V1':
          indexMeta = lampTelemetry.lampMtap3V1TelemetryIndices[key];
          break;
        case 'LAMP_CONTROL_CABINET_1_V1':
          indexMeta = lampTelemetry.lampControlCabinet1V1TelemetryIndices[key];
          break;
        case 'GATEWAY_CONTROL_CABINET_1_V1':
          indexMeta = controlCabinetTelemetry.GatewayControlCabinet1V1TelemetryIndices[key];
          break;
        default:
          indexMeta = lampTelemetry.lampTelemetryIndices[key];
          break;
      }

      if (this.deviceMaxPower) {
        if (key === 'activePower') {
          indexMeta.max = this.deviceMaxPower * 1.1;
        } else if (key === 'current') {
          indexMeta.max = (this.deviceMaxPower * 1.1) / 230;
        }
      }
      let value: number | null;
      if (!this.telemetryData) {
        value = null;
      } else if (key === 'measuredDuty' && this.deviceMaxPower) {
        value = Math.abs(Math.round((this.telemetryData?.telemetry.loadsidePower / this.deviceMaxPower) * 100));
      // } else if (key === 'inputs' || key === 'outputs') {
      //   value = this.telemetryData?.telemetry[key];
      } else if (key === 'control') {
        value = gatewayControlOptions[this.telemetryData?.telemetry[key]];
      // } else if (key === 'doorOpen') {
      //   value = this.telemetryData?.telemetry[key];
      } else {
        if (typeof this.telemetryData?.telemetry[key] === 'number') {
          value = Math.abs(this.telemetryData?.telemetry[key]);
        }
        value = this.telemetryData?.telemetry[key];
      }
      const name = this.$t(`telemetries.${key}`);
      result.push({
        ...indexMeta,
        value,
        name
      });
    });
    return result;
  }

  fetchConsumption() {
    if (!this.selectedDevice) return;
    this.fetchDeviceKobize({ device: this.selectedDevice! });
  }

  @Watch('$i18n')
  on$i18nchange() {
    this.$forceUpdate();
  }

  @Watch('selectedDevice')
  onSelectedDeviceChange() {
    this.fetchConsumption();
    clearTimeout(this.intervalID);
  }

  mounted() {
    this.fetchConsumption();
  }

  destroyed() {
    clearTimeout(this.intervalID);
  }
}
